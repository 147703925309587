import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, {useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { uploadImageOnAWS } from "../../aws/Service";
import { add_blog } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import ReactQuill from "react-quill";

const Editor = ({ content, onChange }) => {
    return (
        <ReactQuill
            value={content}
            onChange={onChange}
            modules={Editor.modules}
            formats={Editor.formats}
            theme="snow"
            placeholder="Write something..."
            style={{ borderRadius: '10px' }}
        />
    );
};

Editor.modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
            // "link",
         "image"],
        ["clean"],
    ],
};

Editor.formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    // "link",
    "image",
];

export default function AddBlog({isLoader}) {
    const navigate = useNavigate()
    const [previewImageUrls, setPreviewImageUrls] = useState([]);
    const [previewVideoUrls, setPreviewVideoUrls] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    const AdminId = localStorage.getItem("aID")

    const blogSchema = Yup.object({
        title: Yup.string()
            .matches(/^[a-zA-Z\s]+$/, 'Please enter proper title')
            .required("Please enter title"),
        description: Yup.string().required("Please add description"),
        link: Yup.string().test('is-url', 'Invalid URL format', function (value) {
            if (!value) return true;
            const regex = /^(https?|http?):\/\/[^\s$.?#].[^\s]*$/;
            return regex.test(value);
        }).nullable().notRequired(),
        images: Yup.array().min(1, 'At least one image is required').nullable().notRequired(),
        videos: Yup.array().min(1, 'At least one video is required').nullable().notRequired()
    });

    const blogForm = useFormik({
        initialValues: {
            title: '',
            description: '',
            link: ''
        },
        validationSchema: blogSchema,
        onSubmit: async (values) => {
            var media = [];
            // Upload images
            if (values.images && values.images.length > 0) {
                for (const image of values.images) {
                    var blog_image = await uploadImageOnAWS(image, "blogs");
                    media.push({ media_type: 'image', name: blog_image });
                }
            }
            // Append videos
            if (values.videos && values.videos.length > 0) {
                for (const video of values.videos) {
                    var blog_video = await uploadImageOnAWS(video, "blogs");
                    media.push({ media_type: 'video', name: blog_video });
                }
            }

            var obj = {
                title: values.title,
                description: values.description,
                admin_id: AdminId,
                media: media
            }
            if (values.link) {
                obj.links = values.link
            }
            isLoader(true)
            const response = await add_blog(obj);
            if (response.code == 1) {
                TOAST_SUCCESS(response.message);
                navigate('/blog');
                isLoader(false)
            } else {
                TOAST_ERROR(response.message);
                isLoader(false)
            }
        }
    });

    const handleDescriptionChange = (value) => {
        blogForm.setFieldValue('description', value);
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'images' || name === 'videos') {
            // Handle multiple files
            const fileArray = Array.from(files);
            blogForm.setFieldValue(name, fileArray);

            if (name === 'images') {
                const imageUrls = fileArray.map(file => URL.createObjectURL(file));
                setPreviewImageUrls(imageUrls);
            } else if (name === 'videos') {
                const videoUrls = fileArray.map(file => URL.createObjectURL(file));
                setPreviewVideoUrls(videoUrls);
            }
        } else {
            // Handle other form fields
            blogForm.setFieldValue(name, value);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const displayError = (fieldName) =>
        blogForm.touched[fieldName] && blogForm.errors[fieldName];

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <div className="mx-5 mt-4">
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>

                        <span className="ms-2">Back</span>
                    </span>
                    <p className="d-flex justify-content-center fs-3 fw-500"> Add Blog</p>
                    <div className="row align-items-center justify-content-center mt-5 mb-5">
                        <div className="col-lg-8">
                            <div className="card border-purple p-5" >

                                <form name='blogForm' noValidate onSubmit={async (e) => {
                                    e.preventDefault();
                                    setIsSubmitted(true);
                                    await blogForm.submitForm();
                                }}>
                                    <div className="mb-4">
                                        <label htmlFor="title" className="form-label">Enter Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            placeholder="Enter title"
                                            name="title"
                                            onChange={blogForm.handleChange}
                                            value={blogForm.values.title}
                                        />
                                        {displayError("title") && (
                                            <div className="text-danger">{blogForm.errors.title}</div>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="description" className="form-label">Enter Description</label>
                                        <Editor
                                            content={blogForm.values.description}
                                            onChange={handleDescriptionChange} />
                                        {displayError("description") && (
                                            <div className="text-danger">{blogForm.errors.description}</div>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="link" className="form-label">Enter link Or URL</label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            id="link"
                                            placeholder="Enter link Or url"
                                            name="link"
                                            onChange={blogForm.handleChange}
                                            value={blogForm.values.link}
                                        />
                                        {displayError("link") && (
                                            <div className="text-danger">{blogForm.errors.link}</div>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label className="form-label" htmlFor="images">Upload images if any</label>
                                        <input type="file" className="form-control" id="images" multiple accept=".jpg, .jpeg, .png" onChange={handleChange} name="images" />
                                        {isSubmitted && !blogForm.values.images?.length && <p className="text-danger mt-1">At least one image is required</p>}
                                    </div>

                                    <div className="row">
                                        {blogForm.values.images && blogForm.values.images.map((image, index) => (
                                            <div className="col-md-2 mb-3" key={index}>
                                                <div className="card image-card" style={{ height: "150px", width: "150px" }}>
                                                    <img src={URL.createObjectURL(image)} alt={`Selected Image ${index}`} style={{ height: "150px", width: "150px" }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div>
                                        <label className="form-label" htmlFor="videos">Upload video if any</label>
                                        <input type="file" className="form-control" id="videos" multiple accept="video/*" onChange={handleChange} name="videos" />
                                        {isSubmitted && !blogForm.values.videos?.length && <p className="text-danger mt-1">At least one video is required</p>}
                                    </div>

                                    <div className="row">
                                        {blogForm.values.videos && blogForm.values.videos.map((video, index) => (
                                            <div className="col-md-2 mb-2" key={index}>
                                                <div className="video-card" style={{ height: "150px", width: "150px" }}>
                                                    <video controls style={{ height: "150px", width: "150px" }}>
                                                        <source src={URL.createObjectURL(video)} type="video/mp4" style={{ height: "150px", width: "150px" }} />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="d-flex justify-content-start mt-5">
                                        <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-1 d-inline-block" disabled={isSubmitDisabled}>
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </>
}