import { useNavigate, useLocation } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { admin_permission_detail, News_Letter_Details } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { Link } from "react-router-dom";

export default function NewsLetterDetails({ isLoader }) {
    const [data, setData] = useState({});
    const [permissions, setPermission] = useState([]);
    const [role, setRole] = useState(localStorage.getItem("adminRole"));
    const [adminId, setAdminId] = useState(localStorage.getItem("aID"));
    const navigate = useNavigate();
    const location = useLocation();
    const newsLetterId = location.state && location.state.id;

    const handleGetData = () => {
        isLoader(true);
        News_Letter_Details({ newsletter_id: newsLetterId }).then((response) => {
            console.log('response :', response);
            if (response.code == 1) {
                setData(response.data);
                isLoader(false)
            } else {
                setData({});
                isLoader(false)
            }
            isLoader(false);
        }).catch(() => {
            setData({});
        });
    };

    useEffect(() => {
        handleGetData();
        if (role !== "S") {
            subAdminPermission();
        }
    }, []);

    const subAdminPermission = () => {
        admin_permission_detail({ id: adminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                    setPermission([]);
                }
            })
            .catch(() => {
                setPermission([]);
            });
    };

    const blogPermission = permissions.find(permission => permission.menu_name === "Newsletter");

    // Check if add, edit, delete is allowed for "Blogs"
    const isAddAllowed = blogPermission && blogPermission.add === 1;
    const isEditAllowed = blogPermission && blogPermission.edit === 1;
    const isDeleteAllowed = blogPermission && blogPermission.delete === 1;

    const handleBack = () => {
        navigate(-1);
    };

    const openLinkInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const handleVideoClick = (videoUrl) => {
        window.open(videoUrl, "_blank", "noopener,noreferrer");
    };

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <Link className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </Link>
                                <Link to="index.php" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </Link>
                            </div>
                        </div>
                    </header>
                    <span onClick={handleBack} className="mx-4 mt-3 back-button">
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313"></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                    <p className="fs-3 fw-500 text-center mb-4">Newsletter Details</p>
                    <div className="d-flex justify-content-center">
                        <div className="d-flex align-items-center card border-purple col-md-6 mb-5 p-2  mt-3">
                            {data.id ? (
                                <div className="newsletter-details mb-3">
                                    <p className="fw-500 fs-4 text-center mt-3 text-purple">{data.title}</p>
                                    <div className="descriptions">
                                        {data.description.map(desc => (
                                            <div key={desc.id} className="description mb-2 p-3">
                                                {desc.attachment && (
                                                    <div className="attachment mt-2 position-relative col-md-12" > {/* Set the width as needed */}
                                                        {desc.attachment_type === "image" ? (
                                                            <img
                                                                src={desc.attachment}
                                                                alt="Attachment"
                                                                className="img-fluid"
                                                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                                                            />
                                                        ) : desc.attachment_type === "video" ? (
                                                            <div className="position-relative" style={{ width: '100%' }}>
                                                                <img
                                                                    src={desc.thumb_image}
                                                                    alt="Thumbnail"
                                                                    className="img-fluid"
                                                                    style={{ width: '100%', height: 'auto', borderRadius: '8px', cursor: 'pointer' }}
                                                                    onClick={() => handleVideoClick(desc.attachment)}
                                                                />
                                                                <div
                                                                    className="play-icon position-absolute top-50 start-50 translate-middle"
                                                                    onClick={() => handleVideoClick(desc.attachment)}
                                                                    style={{ width: '50px', height: '50px' }}
                                                                >
                                                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <circle cx="25" cy="25" r="25" fill="rgba(0, 0, 0, 0.6)" />
                                                                        <path d="M15 12.5V37.5L37.5 25L15 12.5Z" fill="white" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <a href={desc.attachment} target="_blank" rel="noopener noreferrer">View Attachment</a>
                                                        )}
                                                    </div>
                                                )}
                                                <div dangerouslySetInnerHTML={{ __html: desc.description }} className="mt-3" />
                                                {desc.link && (
                                                    <div className="mt-4 mb-2 d-flex justify-content-center">
                                                        <button
                                                            onClick={() => openLinkInNewTab(desc.link)}
                                                            // className="image-button"
                                                            // style={{width : "600px"}}
                                                            className="btn-theme col-md-12"
                                                        >
                                                            Learn More
                                                        </button>
                                                    </div>
                                                )}

                                                <hr style={{ "border": "none", "border-top": "2px solid #5d0968" }} className="mt-3" />

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <p className="text-purple fw-500 fs-4">No data available</p>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
