import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useRef, useState } from "react";
import { orgApplications, organizationStepAccept, sendComment } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Swal from "sweetalert2";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import * as Yup from "yup";
import { useFormik } from "formik";

export default function OApplication({ isLoader }) {
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const orgId = location.state && location.state.oID
    const email = location.state && location.state.email

    const GetOApplicationData = () => {
        isLoader(true)
        orgApplications({ org_id: orgId }).then((response) => {
            if (response.code == 1) {
                setData(response.data[0])
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    useEffect(() => {
        GetOApplicationData();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleAccpet = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to accept organization application?",
            showCancelButton: true,
            confirmButtonText: "Accept",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objAccept = {
                        org_id: orgId,
                        email: email,
                        accept: "2",
                        step: "1",
                    };
                    isLoader(true)
                    organizationStepAccept(objAccept).then((response) => {
                        if (response.code == 1) {
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, step_1: "2" }));
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!");
                }
            }
        });
    }

    const handleReject = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to reject organization application?",
            showCancelButton: true,
            confirmButtonText: "Reject",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objReject = {
                        org_id: orgId,
                        email: email,
                        accept: "3",
                        step: "1",
                    };
                    isLoader(true)
                    organizationStepAccept(objReject).then((response) => {
                        if (response.code == 1) {
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, step_1: "3" }));
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!");
                }
            }
        });
    }

    const fileInputs = useRef([]);
    const resetFileInputs = () => {
        fileInputs.current.forEach((input) => {
            input.value = '';
        });
    };

    const commentSchema = Yup.object({
        comment: Yup.string()
    });

    const commentForm = useFormik({
        initialValues: {
            comment: '',
        },
        validationSchema: commentSchema,

        onSubmit: async (data) => {
            data.id = orgId;
            data.email = email;
            data.comment_for = 2; //comment_for 1 = teacher , 2 = organization , 3 = organization_teacher
            data.step = 1;
            isLoader(true)
            sendComment(data).then((response) => {
                if (response.code == 1) {
                    TOAST_SUCCESS(response.message);
                    commentForm.resetForm();
                    resetFileInputs();
                    GetOApplicationData();
                    isLoader(false)
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false)
                }
            });
        },
    });

    const displayError = (fieldName) =>
        commentForm.touched[fieldName] && commentForm.errors[fieldName];

    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </header>
        <section className="main_sec">
            <div className="mt-4 mx-5 mb-5">
                <div>
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                </div>

                {(Object.keys(data).length > 0 ? (
                    <>
                        <p className="ms-3 fs-3 fw-500 text-center mb-3">Organization Application</p>
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col col-lg-8 mb-4 mb-lg-0">
                                <div className="card mb-3 p-3" style={{ borderRadius: ".5rem" }}>
                                    <div className="row g-0">
                                        <div className="col-md-12">
                                            <div className="card-body p-4">
                                                <p className="mb-2 fs-5 fw-600">Application</p>
                                                <hr className="mt-0 mb-4" />
                                                <div className="row pt-1">
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Legal Name</p>
                                                        <p className="text-muted mt-1">{data.legal_name}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Organization Name</p>
                                                        <p className="text-muted mt-1">{data.org_name}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Company Registration / Business Number</p>
                                                        <p className="text-muted mt-1">{data.reg_number}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Organization Registration Document</p>
                                                        {data.register_doc && (
                                                            <a href={data.register_doc} className="text-purple underline mt-1">View Document</a>
                                                        )}
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Primary Category Of Your Business</p>
                                                        <p className="text-muted mt-1">{data.primary_category_one}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">How Many Teachers From Your Organization Do You Anticipate Will Begin Teaching On StudentScholars Within The Next Month? If You Are The Only Teacher In Your Organization, Please Apply As An Independent Teacher Instead.</p>
                                                        <p className="text-muted mt-1">{data.many_org}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">When Was Your Organization Founded?</p>
                                                        <p className="text-muted mt-1">{data.org_founder}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Organization Email</p>
                                                        <p className="text-muted mt-1">{data.org_email}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Organization Telephone</p>
                                                        <p className="text-muted mt-1">{data.org_telephone}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Full Address Of Your Company</p>
                                                        <p className="text-muted mt-1">{data.org_address}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Your Company Website / URL</p>
                                                        <p className="text-muted mt-1">{data.org_url}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">What Type Of Modes Of Classes Will Your Organization Provide</p>
                                                        <p className="text-muted mt-1">{data.org_types}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Do you have a valid liability insurance that covers against third party injury of any kind arising from your premises and provision of any Class and/or Content, including teacher abuse claims coverage? Required for In-person classes.</p>
                                                        <p className="text-muted mt-1">{data.org_insurance}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">What Is The Expiry Of The Insurance ? Month And Year</p>
                                                        <p className="text-muted mt-1">{data.ecp_insurance_date}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Please Upload Your Insurance Details. Required For In-Person Classes </p>
                                                        {data.insurance_document && (
                                                            <a href={data.insurance_document} className="text-purple underline mt-1">View Document</a>
                                                        )}
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Who Is The Primary Contact For Your Organization? </p>
                                                        <p className="text-muted mt-1">{data.who_primary_contact}</p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">What Is Your Primary Contact's Title? </p>
                                                        <p className="text-muted mt-1">{data.primary_title}</p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">What Is Primary Contact's Phone Number?</p>
                                                        <p className="text-muted mt-1">{data.primary_phone_number}</p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Please Include Any Information Around Requirements For Your Teachers And Any Training You Do, As Well As How Long Your Educators Have Been Working With Children Or Adults.  </p>
                                                        <p className="text-muted mt-1">{data.info_requirements_one}</p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">What Ages Do You Work With? Are You Targeting Special Interest Groups? What Is The Group Size For Most Of Your Classes?</p>
                                                        <p className="text-muted mt-1">{data.group_size}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Tell Us About The Types Of Classes You Offer. Please Be Specific Regarding The Topics You Cover, The Length Of Programming, And Where/How You Offer Classes.</p>
                                                        <p className="text-muted mt-1">{data.offer_classes}</p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">How Did You Hear About StudentScholars</p>
                                                        <p className="text-muted mt-1">{data.hear_student_scholar}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Can You Point Us To Any Public Reviews Or Testimonials About Your Programs? (Please Provide Links.) </p>
                                                        <p className="text-muted mt-1">{data.public_review}</p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Record A 2-3 Minute Video To Let Us Know About Your Organization. This Video Will Stay Private And Will Not Be Visible To Families. You Should Let Us Know Why Your Organization Should Join StudentScholars And What Your Organization Could Offer The Students. </p>
                                                        {data.introduction_video && (
                                                            <a href={data.introduction_video} className="text-purple underline mt-1">View Video</a>
                                                        )}
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Comments/ Any Additional Information You Would Like Us To Know</p>
                                                        <p className="text-muted mt-1">{data.comments}</p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Admin Comments</p>
                                                        <p className="text-muted mt-1">{data.org_comment}</p>
                                                    </div>
                                                </div>

                                                <form className="custom_form mt-4" name="commentForm" onSubmit={commentForm.handleSubmit}>

                                                    <div className="form-group mb-4">
                                                        <p className="fw-500 mb-2">Any comments you have, please add them here for the teacher.</p>
                                                        <textarea type="text" className="form-control" name="comment" value={commentForm.values.comment} rows="5" cols="33" onChange={commentForm.handleChange} ></textarea>
                                                        {displayError("comment") && (
                                                            <div className="text-danger">{commentForm.errors.comment}</div>
                                                        )}
                                                        <div className="button mt-2 mb-2 d-flex justify-content-end">
                                                            <button type="submit" className="btn-theme px-4 py-2  text-center text-dif-black fw-500 bg-yellow mb-xl-0 mb-3 d-inline-block">Send</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                {(data.step_1 == 0 || data.step_1 == 1) &&
                                                    <div className="d-flex justify-content-center mt-3">
                                                        <button className="btn-theme bg-yellow mx-1" onClick={() => handleAccpet()}>
                                                            {data.step_1 == "2" ? "Accpted" : "Accept"}
                                                        </button>
                                                        <button className="btn-theme mx-1" onClick={() => handleReject()}>
                                                            {data.step_1 == "3" ? "Rejected" : "Reject"}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <p className="text-center fs-5 fw-500 text-danger mt-5">The Organization has not filled out the application form.</p>
                    </div>
                )
                )}

            </div>
        </section>
    </>
}

