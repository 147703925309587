import { useNavigate, useLocation } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { uploadImageOnAWS } from "../../aws/Service";
import { delete_blog_media, get_blog_details, update_blog } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";

const Editor = ({ content, onChange, onBlur }) => {
    return (
        <ReactQuill
            value={content}
            onChange={onChange}
            onBlur={onBlur}
            modules={Editor.modules}
            formats={Editor.formats}
            theme="snow"
            placeholder="Write something..."
            style={{ borderRadius: '10px' }}
        />
    );
};

Editor.modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
    ],
};

Editor.formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
];

const EditBlog = ({ isLoader }) => {
    const navigate = useNavigate();
    const [previewImageUrls, setPreviewImageUrls] = useState([]);
    const [previewVideoUrls, setPreviewVideoUrls] = useState([]);
    const [newpreviewImageUrls, setNewPreviewImageUrls] = useState([]);
    const [newpreviewVideoUrls, setNewPreviewVideoUrls] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [blogData, setBlogData] = useState(null);
    const location = useLocation();
    const BlogId = location.state && location.state.BlogId;

    const AdminId = localStorage.getItem("aID");

    const blogSchema = Yup.object({
        title: Yup.string()
            .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, 'Please enter proper title')
            .required("Please enter title"),
        description: Yup.string().required("Please add description"),
        description: Yup.string().trim().nullable().notRequired(),
        link: Yup.string().test('is-url', 'Invalid URL format', function (value) {
            if (!value) return true;
            const regex = /^(https?|http?):\/\/[^\s$.?#].[^\s]*$/;
            return regex.test(value);
        }).nullable().notRequired(),
        images: Yup.array().min(1, 'At least one image is required').nullable().notRequired(),
        videos: Yup.array().min(1, 'At least one video is required').nullable().notRequired()
    });


    const blogForm = useFormik({
        initialValues: {
            title: '',
            media_type: '',
            description: '',
            link: '',
            images: [],
            videos: []
        },
        validationSchema: blogSchema,
        onSubmit: async (values) => {

            var media = [];
            // Upload images
            if (values.images && values.images.length > 0) {
                for (const image of values.images) {
                    var blog_image = await uploadImageOnAWS(image, "blogs");
                    media.push({ media_type: 'image', name: blog_image });
                }
            }
            // Append videos
            if (values.videos && values.videos.length > 0) {
                for (const video of values.videos) {
                    var blog_video = await uploadImageOnAWS(video, "blogs");
                    media.push({ media_type: 'video', name: blog_video });
                }
            }

            if (values.description) {
                blogForm.setFieldTouched('description', true);
            }

            const obj = {
                title: values.title,
                admin_id: AdminId,
                media: media,
                blog_id: BlogId,
                description: values.description,
                links: values.link
            };
            isLoader(true)
            const response = await update_blog(obj);
            if (response.code == 1) {
                isLoader(false)
                TOAST_SUCCESS(response.message);
                navigate('/blog');
            } else {
                isLoader(false)
                TOAST_ERROR(response.message);
            }
        },
    });

    const fetchBlogData = async () => {
        try {
            isLoader(true)
            const blog = await get_blog_details({ blog_id: BlogId });
            if (blog) {
                isLoader(false)
                setBlogData(blog);
                const { title, description, link } = blog?.data?.blog_details;
                const images = blog?.data?.blog_media.filter(media => media.type === 'image').map(media => ({
                    url: media.media,
                    id: media.id
                }));
                const videos = blog?.data?.blog_media.filter(media => media.type === 'video').map(media => ({
                    url: media.media,
                    id: media.id
                }));

                blogForm.setValues({
                    title: title || '',
                    description: description || '',
                    link: link || ''
                });

                if (blog?.data?.blog_media) {
                    setPreviewImageUrls(images);
                    setPreviewVideoUrls(videos);
                }
            } else {
                isLoader(false)
            }
        } catch (error) {
            isLoader(false)
            console.error("Error fetching blog:", error);
        }
    };

    useEffect(() => {
        fetchBlogData();
    }, []);

    const handleDescriptionChange = (value) => {
        blogForm.setFieldValue('description', value);
        blogForm.setFieldTouched('description', true);
    };

    // const handleDescriptionChange = (value) => {
    //     const isEmptyDescription = /^\s*<p><br><\/p>\s*$/i.test(value);
    //     const newValue = isEmptyDescription ? '' : value;
    //     blogForm.setFieldValue('description', newValue);
    //     if (newValue === '') {
    //         blogForm.setFieldError('description', 'Please add description');
    //     } else {
    //         blogForm.setFieldError('description', undefined);
    //     }
    // };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'images' || name === 'videos') {
            const fileArray = Array.from(files);
            blogForm.setFieldValue(name, fileArray);

            if (name === 'images') {
                const imageUrls = fileArray.map(file => URL.createObjectURL(file));
                setNewPreviewImageUrls(imageUrls);
            } else if (name === 'videos') {
                const videoUrls = fileArray.map(file => URL.createObjectURL(file));
                setNewPreviewVideoUrls(videoUrls);
            }
        } else {
            blogForm.setFieldValue(name, value);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const displayError = (fieldName) =>
        blogForm.touched[fieldName] && blogForm.errors[fieldName];

    if (!blogData) {
        return <div>Loading...</div>;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);

        let updatedErrors = { ...blogForm.errors };

        if (previewImageUrls.length === 0 && !blogForm.values.images?.length) {
            updatedErrors = {
                ...updatedErrors,
                images: 'At least one image is required'
            };
        } else {
            delete updatedErrors.images;
        }

        if (previewVideoUrls.length === 0 && !blogForm.values.videos?.length) {
            updatedErrors = {
                ...updatedErrors,
                videos: 'At least one video is required'
            };
        } else {
            delete updatedErrors.videos;
        }

        // Check if the URL field is cleared
        if (!blogForm.values.link) {
            delete updatedErrors.link;
        }

        if (!blogForm.values.description) {
            blogForm.setFieldValue('description', undefined);
        }

        if (blogForm.errors.link) {
            // If there's an error, prevent form submission
            console.log('Form submission aborted due to validation errors');
            return;
        }

        blogForm.setErrors(updatedErrors);

        if (Object.keys(updatedErrors).length === 0) {
            await blogForm.submitForm();
        } else {
            console.log('Form submission aborted due to validation errors');
        }
    };

    const handleRemoveImage = (index, mediaId, e) => {
        e.preventDefault();

        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete this image?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    delete_blog_media({ blog_image_id: mediaId }).then((response) => {
                        if (response.code == 1) {
                            const updatedImages = previewImageUrls.filter((image, idx) => idx !== index);
                            setPreviewImageUrls(updatedImages);
                            const updatedFormImages = blogForm.values.images.filter((image, idx) => idx !== index);
                            blogForm.setFieldValue('images', updatedFormImages);
                            TOAST_SUCCESS(response.message);
                        } else {
                            TOAST_ERROR(response.message);
                        }
                    });
                } catch (error) {
                    console.error("Error deleting category:", error);
                    TOAST_ERROR("Error deleting category");
                }
            }
        });
    };

    const handleRemoveVideo = (index, mediaId, e) => {
        e.preventDefault();

        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete this video?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    delete_blog_media({ blog_image_id: mediaId }).then((response) => {
                        if (response.code == 1) {
                            const updatedVideos = previewVideoUrls.filter((video, idx) => idx !== index);
                            setPreviewVideoUrls(updatedVideos);
                            const updatedFormVideos = blogForm.values.videos.filter((video, idx) => idx !== index);
                            blogForm.setFieldValue('videos', updatedFormVideos);
                            TOAST_SUCCESS(response.message);
                        } else {
                            TOAST_ERROR(response.message);
                        }
                    });
                } catch (error) {
                    console.error("Error deleting category:", error);
                    TOAST_ERROR("Error deleting category");
                }
            }
        });
    };

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="index.php" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="mx-5 mt-4">
                        <span onClick={handleBack}>
                            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                            </svg>
                            <span className="ms-2">Back</span>
                        </span>
                        <p className="d-flex justify-content-center fs-3 fw-500"> Edit Blog</p>
                        <div className="row align-items-center justify-content-center mt-5 mb-5">
                            <div className="col-lg-8">
                                <div className="card border-purple p-5" >
                                    <form name='blogForm' noValidate onSubmit={handleSubmit}>
                                        <div className="mb-4">
                                            <label htmlFor="title" className="form-label">Enter Title</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="title"
                                                placeholder="Enter title"
                                                name="title"
                                                onChange={blogForm.handleChange}
                                                onBlur={blogForm.handleBlur}
                                                value={blogForm.values.title}
                                            />
                                            {blogForm.touched.title && blogForm.errors.title && (
                                                <div className="text-danger">{blogForm.errors.title}</div>
                                            )}
                                        </div>

                                        <div className="mb-4">
                                            <label htmlFor="description" className="form-label">Enter Description</label>
                                            <Editor
                                                content={blogForm.values.description}
                                                onChange={handleDescriptionChange}
                                                onBlur={() => blogForm.setFieldTouched('description', true)}
                                            />
                                            {blogForm.touched.description && blogForm.errors.description && (
                                                <div className="text-danger">{blogForm.errors.description}</div>
                                            )}
                                        </div>

                                        <div className="mb-4">
                                            <label htmlFor="link" className="form-label">Enter link Or URL</label>
                                            <input
                                                type="url"
                                                className="form-control"
                                                id="link"
                                                placeholder="Enter link Or url"
                                                name="link"
                                                onBlur={blogForm.handleBlur}
                                                onChange={blogForm.handleChange}
                                                value={blogForm.values.link}
                                            />
                                            {blogForm.touched.link && blogForm.errors.link && (
                                                <div className="text-danger">{blogForm.errors.link}</div>
                                            )}
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label mb-4" htmlFor="images">Upload images if any</label>

                                            <div className="row">
                                                {previewImageUrls.map((imageUrl, index) => (
                                                    <div className="col-md-2 mb-3" key={index}>
                                                        <div className="card image-card" style={{ height: "150px", width: "150px" }}>
                                                            <img src={imageUrl.url} alt={`Selected Image ${index}`} style={{ height: "150px", width: "150px" }} />
                                                            <button type="button" className="close-button" onClick={(e) => handleRemoveImage(index, imageUrl.id, e)}>&times;</button>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <input type="file" onChange={handleChange} className="form-control" id="images" multiple accept=".jpg, .jpeg, .png" name="images" />
                                            {isSubmitted && ((previewImageUrls.length === 0 && !blogForm.values.images?.length) || (blogForm.errors.images && !blogForm.values.images?.length)) && (
                                                <div className="text-danger mt-1">At least one image is required</div>
                                            )}

                                        </div>

                                        <div className="row">
                                            {newpreviewImageUrls.map((imageUrl, index) => (
                                                <div className="col-md-2 mb-3" key={index}>
                                                    <div className="card image-card" style={{ height: "150px", width: "150px" }}>
                                                        <img src={imageUrl} alt={`Selected Image ${index}`} style={{ height: "150px", width: "150px" }} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <hr />

                                        <div>
                                            <label className="form-label" htmlFor="videos">Upload video if any</label>
                                            <div className="row">
                                                {previewVideoUrls.map((videoUrl, index) => (
                                                    <div className="col-md-2 mb-2" key={index}>
                                                        <div className=" video-container video-card mt-2" style={{ height: "150px", width: "150px" }}>
                                                            <video controls style={{ height: "100px", width: "150px" }}>
                                                                <source src={videoUrl.url} type="video/mp4" style={{ height: "150px", width: "150px" }} />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                            <button type="button" className="close-button" onClick={(e) => handleRemoveVideo(index, videoUrl.id, e)}>&times;</button>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <input type="file" onChange={handleChange} className="form-control" id="videos" multiple accept="video/*" name="videos" />
                                            {isSubmitted && ((previewVideoUrls.length === 0 && !blogForm.values.videos?.length) || (blogForm.errors.videos && !blogForm.values.videos?.length)) && (
                                                <div className="text-danger mt-1">At least one video is required</div>
                                            )}

                                        </div>
                                        <div className="row">
                                            {newpreviewVideoUrls.map((videoUrl, index) => (
                                                <div className="col-md-2 mb-2" key={index}>
                                                    <div className=" video-container video-card mt-2" style={{ height: "150px", width: "150px" }}>
                                                        <video controls style={{ height: "100px", width: "150px" }}>
                                                            <source src={videoUrl} type="video/mp4" style={{ height: "150px", width: "150px" }} />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="d-flex justify-content-start mt-5">
                                            <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-1 d-inline-block" disabled={isSubmitDisabled}>
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default EditBlog;
