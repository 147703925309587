import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useRef, useState } from "react";
import { sendComment, teacherApplications, teacherStepAccept } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useFormik } from "formik";

export default function TApplication({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const teacherID = location.state && location.state.tID;

    const GetTApplicationData = () => {
        isLoader(true)
        teacherApplications({ teacher_id: teacherID }).then((response) => {
            console.log('response :', response);
            if (response.code == 1) {
                setData(response.data[0])
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    useEffect(() => {
        GetTApplicationData();
    }, [teacherID]);

    const handleBack = () => {
        navigate(-1);
    };

    const handleAccpet = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to accept teacher application?",
            showCancelButton: true,
            confirmButtonText: "Accept",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objAccept = {
                        teacher_id: teacherID,
                        email: data.email,
                        accept: "2",
                        step: "1",
                        accept_for: "1"
                    };
                    isLoader(true)
                    teacherStepAccept(objAccept).then((response) => {
                        if (response.code == 1) {
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, step_1: "2" }));
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!");
                }
            }
        });
    }

    const handleReject = () => {

        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to reject teacher application?",
            showCancelButton: true,
            confirmButtonText: "Reject",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objReject = {
                        teacher_id: teacherID,
                        email: data.email,
                        accept: "3",
                        step: "1",
                        accept_for: "1"
                    };
                    isLoader(true)
                    teacherStepAccept(objReject).then((response) => {
                        if (response.code == 1) {
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, step_1: "3" }));
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!");
                }
            }
        });
    }

    const fileInputs = useRef([]);
    const resetFileInputs = () => {
        fileInputs.current.forEach((input) => {
            input.value = '';
        });
    };

    const commentSchema = Yup.object({
        comment: Yup.string()
    });

    const Email = data.email;
    const commentForm = useFormik({
        initialValues: {
            comment: '',
        },
        validationSchema: commentSchema,

        onSubmit: async (data) => {
            data.id = teacherID;
            data.email = Email;
            data.comment_for = 1; //comment_for 1 = teacher , 2 = organization , 3 = organization_teacher
            data.step = 1;
            isLoader(true)
            sendComment(data).then((response) => {
                if (response.code == 1) {
                    TOAST_SUCCESS(response.message);
                    commentForm.resetForm();
                    resetFileInputs();
                    GetTApplicationData();
                    isLoader(false)
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false)
                }
            });
        },
    });

    const displayError = (fieldName) =>
        commentForm.touched[fieldName] && commentForm.errors[fieldName];

    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </header>
        <section className="main_sec">
            <div className="mt-4 mx-5 mb-5">
                <div>
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                </div>
                {(Object.keys(data).length > 0 ? (
                    <>
                        <p className="ms-3 fs-3 fw-500 text-center mb-3">Teacher Application</p>
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col col-lg-8 mb-4 mb-lg-0">
                                <div className="card mb-3 p-3" style={{ borderRadius: ".5rem" }}>
                                    <div className="row g-0">
                                        <div className="col-md-12">
                                            <div className="card-body p-4">
                                                <p className="mb-2 fs-5 fw-600">Application</p>
                                                <hr className="mt-0 mb-4" />
                                                <div className="row pt-1">
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">First Name</p>
                                                        <p className="text-muted mt-1">{data.first_name}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Public Name</p>
                                                        <p className="text-muted mt-1">{data.public_name}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Legal Name</p>
                                                        <p className="text-muted mt-1">{data.legal_name}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Email</p>
                                                        <p className="text-muted mt-1">{data.email}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Phone</p>
                                                        <p className="text-muted mt-1">{data.phone_no}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Country</p>
                                                        <p className="text-muted mt-1">{data.country}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Elecator Speech </p>
                                                        <p className="text-muted mt-1">{data.elecator_speech}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Academy Degree</p>
                                                        <p className="text-muted mt-1">{data.academy_degree}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Year of Teaching Experience</p>
                                                        <p className="text-muted mt-1">{data.teaching_exp}</p>
                                                    </div>

                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Teaching Resume</p>
                                                        {data.teacher_resume && (
                                                            <a href={data.teacher_resume} className="text-purple underline mt-1">View Document</a>
                                                        )}
                                                    </div>

                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Social Profile Link</p>
                                                        <p className="text-muted mt-1">{data.social_profile_link}</p>
                                                    </div>

                                                    <div className="col-8 mb-4">
                                                        <p className="fw-500">Education And Professional Qualifications Certificate</p>
                                                        {data.teacher_certificate && (
                                                            <a href={data.teacher_certificate} className="text-purple underline mt-1" >View Document</a>
                                                        )}
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500"> Additional Material Relating To Teaching Experience</p>
                                                        {data.teacher_add_material && (
                                                            <a href={data.teacher_add_material} className="text-purple underline mt-1">View Document</a>
                                                        )}
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Topics About Teaching</p>
                                                        <p className="text-muted mt-1">{data.topic_about}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Sample Video</p>
                                                        {data.teacher_sample_video && (
                                                            <a href={data.teacher_sample_video} className="text-purple underline mt-1" >View Video</a>
                                                        )}
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Additional Information</p>
                                                        <p className="text-muted mt-1">{data.additional_info}</p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Admin Comments</p>
                                                        <p className="text-muted mt-1">{data.comments}</p>
                                                    </div>
                                                </div>

                                                <form className="custom_form mt-4" name="commentForm" onSubmit={commentForm.handleSubmit}>

                                                    <div className="form-group mb-4">
                                                        <p className="fw-500 mb-2">Any comments you have, please add them here for the teacher.</p>
                                                        <textarea type="text" className="form-control" name="comment" value={commentForm.values.comment} rows="5" cols="33" onChange={commentForm.handleChange} ></textarea>
                                                        {displayError("comment") && (
                                                            <div className="text-danger">{commentForm.errors.comment}</div>
                                                        )}
                                                        <div className="button mt-2 mb-2 d-flex justify-content-end">
                                                            <button type="submit" className="btn-theme px-4 py-2  text-center text-dif-black fw-500 bg-yellow mb-xl-0 mb-3 d-inline-block">Send</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                {(data.step_1 == 0 || data.step_1 == 1) &&

                                                    <div className="d-flex justify-content-center mt-3">
                                                        <button className="btn-theme bg-yellow mx-1" onClick={() => handleAccpet()}>
                                                            {data.step_1 == "2" ? "Accpted" : "Accept"}
                                                        </button>
                                                        <button className="btn-theme mx-1" onClick={() => handleReject()}>
                                                            {data.step_1 == "3" ? "Rejected" : "Reject"}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <p className="text-center fs-5 fw-500 text-danger mt-5">The Teacher has not filled out the application form.</p>
                    </div>
                )
                )}

            </div>
        </section>
    </>
}

