import React from 'react'
import { Helmet } from 'react-helmet'

export default function Loader({ isActive }) {
    if (!isActive) return <></>;
    return (
        <>
            <Helmet>
                <style>{`
.load-bgblack {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 70%);
    z-index: 1111;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loader-image {
    width: 70px;
    height: 70px;
    // animation: rotate 1s infinite;
}
@keyframes rotate {
    100% {
        rotate: 360deg;
    }
}
.please-wait {
    margin-top: 15px;
    font-size: 16px;
    color: #570861;
}
                `}</style>
            </Helmet>
            <div className='load-bgblack'>
                <img src="./assets/images/hat_purple_1.png" alt="Loading" className="loader-image" />
                <div className="please-wait">Please wait...</div>
            </div>
        </>
    )
}
