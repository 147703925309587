import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useRef, useState } from "react";
import { orgAuthorization, organizationStepAccept, sendComment } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useFormik } from "formik";

export default function OAuthorization({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const orgId = location.state && location.state.orgId
    const email = location.state && location.state.email

    const GetOrgAuthorization = () => {
        isLoader(true)
        orgAuthorization({ org_id: orgId }).then((response) => {
            console.log('response :', response);
            if (response.code == 1) {
                setData(response.data)
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    useEffect(() => {
        GetOrgAuthorization();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleAccpet = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to accept organization authorization?",
            showCancelButton: true,
            confirmButtonText: "Accept",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objAccept = {
                        org_id: orgId,
                        email: email,
                        accept: "2",
                        step: "4",
                    };
                    isLoader(true)
                    organizationStepAccept(objAccept).then((response) => {
                        if (response.code == 1) {
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, step_4: "2" }));
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!")
                }
            }
        });
    }

    const handleReject = () => {

        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to reject organization authorization?",
            showCancelButton: true,
            confirmButtonText: "Reject",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objReject = {
                        org_id: orgId,
                        email: email,
                        accept: "3",
                        step: "4",
                    };
                    isLoader(true)
                    organizationStepAccept(objReject).then((response) => {
                        if (response.code == 1) {
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, step_4: "3" }));
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!")
                }
            }
        });
    }

    const fileInputs = useRef([]);
    const resetFileInputs = () => {
        fileInputs.current.forEach((input) => {
            input.value = '';
        });
    };

    const commentSchema = Yup.object({
        comment: Yup.string()
    });

    const commentForm = useFormik({
        initialValues: {
            comment: '',
        },
        validationSchema: commentSchema,

        onSubmit: async (data) => {

            data.id = orgId;
            data.email = email;
            data.comment_for = 2; //comment_for 1 = teacher , 2 = organization , 3 = organization_teacher
            data.step = 4;
            isLoader(true)
            sendComment(data).then((response) => {
                if (response.code == 1) {
                    TOAST_SUCCESS(response.message);
                    commentForm.resetForm();
                    resetFileInputs();
                    GetOrgAuthorization();
                    isLoader(false)
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false)
                }
            });
        },
    });

    const displayError = (fieldName) =>
        commentForm.touched[fieldName] && commentForm.errors[fieldName];

    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </header>
        <section className="main_sec">
            <div className="mt-4 mx-5 mb-5">
                <div>
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                </div>

                {(Object.keys(data).length > 0 ? (
                    <>
                        <p className="ms-3 fs-3 fw-500 text-center mb-3">Authorization to teach In-person</p>
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col col-lg-8 mb-4 mb-lg-0">
                                <div className="card mb-3 p-3" style={{ borderRadius: ".5rem" }}>
                                    <div className="row g-0">
                                        <div className="col-md-12">
                                            <div className="card-body p-4">
                                                <p className="mb-2 fs-5 fw-600">Information</p>
                                                <hr className="mt-0 mb-4" />
                                                <div className="row pt-1">
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Legally Registered Full Name</p>
                                                        <p className="text-muted mt-1">{data?.reg_full_name}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500"> Position That You Hold In The Organzation</p>
                                                        <p className="text-muted mt-1">{data?.position}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Please Confirm If You Are Authorized To Represent The Organization.</p>
                                                        <p className="text-muted mt-1">{data?.authorized}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">The Address Of The Legally Authorized Location Of Your Organization In Which That You Will Provide Lessons At.</p>
                                                    </div>
                                                    <div className="col-6 mb-4">
                                                        <p className="fw-500">Street Number</p>
                                                        <p className="text-muted mt-1">{data?.street_number}</p>
                                                    </div>
                                                    <div className="col-6 mb-4">
                                                        <p className="fw-500">Street Name</p>
                                                        <p className="text-muted mt-1">{data?.street_name}</p>
                                                    </div>
                                                    <div className="col-6 mb-4">
                                                        <p className="fw-500">Country</p>
                                                        <p className="text-muted mt-1">{data?.country}</p>
                                                    </div>
                                                    <div className="col-6 mb-4">
                                                        <p className="fw-500">Province/State</p>
                                                        <p className="text-muted mt-1">{data?.state}</p>
                                                    </div>
                                                    <div className="col-6 mb-4">
                                                        <p className="fw-500">City</p>
                                                        <p className="text-muted mt-1">{data?.city}</p>
                                                    </div>
                                                    <div className="col-6 mb-4">
                                                        <p className="fw-500">Zip Code</p>
                                                        <p className="text-muted mt-1">{data?.zip_code}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Please Confirm If The Physical Location/Business Is Operating Meeting The Guidelines As Per The Laws And Regulations Of Your City/Country.</p>
                                                        <p className="text-muted mt-1">{data?.physical_location}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Do You Have A Google My Business Account ?</p>
                                                        <p className="text-muted mt-1">{data?.google_business}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Google My Business Account URL Link</p>
                                                        <p className="text-muted mt-1">{data?.business_url}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Please Share Any Social Media Accounts That Shows Your Business Location</p>
                                                        <p className="text-muted mt-1">{data?.social_media_account}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Please Upload A Liability Insurance Document To Confirm That Your Organization Is Insured</p>
                                                        {data.insurance_document && (
                                                            <a href={data?.insurance_document} className="text-purple underline mt-1">View Document</a>
                                                        )}
                                                    </div>
                                                    {data?.attachments?.attachment_inside.length > 0 && <>
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500 mb-3">Pictures Indside</p>
                                                            <img src={data?.attachments?.attachment_inside[0]?.attachments[0]?.attachment} className="text-muted mt-1 col-md-4 mx-1 my-1" style={{ height: "290px", width: "290px" }} />
                                                            <img src={data?.attachments?.attachment_inside[1]?.attachments[0]?.attachment} className="text-muted mt-1 col-md-4 mx-1 my-1" style={{ height: "290px", width: "290px" }} />
                                                            <img src={data?.attachments?.attachment_inside[2]?.attachments[0]?.attachment} className="text-muted mt-1 col-md-4 mx-1 my-1" style={{ height: "290px", width: "290px" }} />
                                                            <img src={data?.attachments?.attachment_inside[3]?.attachments[0]?.attachment} className="text-muted mt-1 col-md-4 mx-1 my-1" style={{ height: "290px", width: "290px" }} />
                                                            <img src={data?.attachments?.attachment_inside[4]?.attachments[0]?.attachment} className="text-muted mt-1 col-md-4 mx-1 my-1" style={{ height: "290px", width: "290px" }} />
                                                            <img src={data?.attachments?.attachment_inside[5]?.attachments[0]?.attachment} className="text-muted mt-1 col-md-4 mx-1 my-1" style={{ height: "290px", width: "290px" }} />
                                                        </div>
                                                    </>}
                                                    {data?.attachments?.attachment_outside.length > 0 && <>
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500 mb-3">Pictures Outside</p>
                                                            <img src={data?.attachments?.attachment_outside[0]?.attachments[0]?.attachment} className="text-muted mt-1 col-md-4 mx-1 my-1" style={{ height: "290px", width: "290px" }} />
                                                            <img src={data?.attachments?.attachment_outside[1]?.attachments[0]?.attachment} className="text-muted mt-1 col-md-4 mx-1 my-1" style={{ height: "290px", width: "290px" }} />
                                                            <img src={data?.attachments?.attachment_outside[2]?.attachments[0]?.attachment} className="text-muted mt-1 col-md-4 mx-1 my-1" style={{ height: "290px", width: "290px" }} />
                                                        </div>
                                                    </>}


                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Admin Comments</p>
                                                        <p className="text-muted mt-1">{data.comments}</p>
                                                    </div>
                                                </div>

                                                <form className="custom_form mt-4" name="commentForm" onSubmit={commentForm.handleSubmit}>

                                                    <div className="form-group mb-4">
                                                        <p className="fw-500 mb-2">Any comments you have, please add them here for the teacher.</p>
                                                        <textarea type="text" className="form-control" name="comment" value={commentForm.values.comment} rows="5" cols="33" onChange={commentForm.handleChange} ></textarea>
                                                        {displayError("comment") && (
                                                            <div className="text-danger">{commentForm.errors.comment}</div>
                                                        )}
                                                        <div className="button mt-2 mb-2 d-flex justify-content-end">
                                                            <button type="submit" className="btn-theme px-4 py-2  text-center text-dif-black fw-500 bg-yellow mb-xl-0 mb-3 d-inline-block">Send</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                {(data.step_4 == 0 || data.step_4 == 1) &&
                                                    <div className="d-flex justify-content-center mt-3">
                                                        <button className="btn-theme bg-yellow mx-1" onClick={() => handleAccpet()}>
                                                            {data.step_4 == "2" ? "Accpted" : "Accept"}
                                                        </button>
                                                        <button className="btn-theme mx-1" onClick={() => handleReject()}>
                                                            {data.step_4 == "3" ? "Rejected" : "Reject"}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <p className="text-center fs-5 fw-500 text-danger mt-5">The Organization has not filled out the authorization form.</p>
                    </div>
                )
                )}

            </div>
        </section >
    </>
}

