import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { admin_permission_detail, teacherList, userBlockUnblock, userDelete } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import SwalOld from "sweetalert"
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Swal from "sweetalert2";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import customStyles from "../../../utils/dataTableStyles";

export default function Teacher({ isLoader }) {

    const [data, setData] = useState([])
    const [activeActionPending, setActiveActionPending] = useState(false);
    const navigate = useNavigate()
    const [permissions, setPermission] = useState([]);
    const role = localStorage.getItem("adminRole");
    const AdminId = localStorage.getItem("aID")
    const [search, setSearch] = useState('')

    const GetTeacherData = () => {
        isLoader(true)
        teacherList({ search: search }).then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setData(dataWithRowCount);
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    const handleDelete = async (id) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    isLoader(true)
                    userDelete({ id: id }).then((response) => {
                        if (response.code == 1) {
                            setData((prevData) => prevData.filter((user) => user.id !== id))
                            TOAST_SUCCESS(response.message);
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    })
                } catch (error) {
                    TOAST_ERROR("Error deleting teacher");
                }
            }
        });
    };

    const handleView = (id) => {
        navigate('/teacher_details', { state: { teacherId: id } })
    }

    const handleView2 = (id) => {
        navigate('/teaching-class-details', { state: { teacher_id: id } });
    }

    const clickActive = (id, is_active, email, full_name) => {
        const actionText = is_active === 1 ? 'block' : 'unblock';
        setActiveActionPending(true)
        SwalOld({
            title: 'Are you sure?',
            text: 'You want to ' + actionText + ' this teacher ?',
            icon: 'warning',
            buttons: {
                confirm: {
                    text: is_active === 1 ? 'Block' : 'Unblock',
                    className: 'btn-success'
                },
                cancel: 'Cancel',
            },
            dangerMode: true,
        })
            .then((activate) => {
                setActiveActionPending(false);
                if (activate) {
                    if (is_active === 1) {
                        HandleMakeInactive(id, email, full_name);
                    } else {
                        HandleMakeActive(id, email, full_name);
                    }
                } else {
                    GetTeacherData();
                }
            });
    }

    const HandleMakeActive = async (id, email, full_name) => {
        let catid = `${id}`
        let objCategory = {
            id: catid,
            is_active: "1",
            name: full_name,
            email: email
        };
        isLoader(true)
        userBlockUnblock((objCategory)).then((response) => {
            if (response.code == "1") {
                updateLearnerStatus(id, 1);
                isLoader(false)
                TOAST_SUCCESS(response.message);
                GetTeacherData();
            } else {
                isLoader(false)
                TOAST_ERROR(response.message);
            }
        })
    };

    const HandleMakeInactive = async (id, email, full_name) => {
        let catid = `${id}`
        let objCategory = {
            id: catid,
            is_active: "0",
            name: full_name,
            email: email
        };
        isLoader(true)
        userBlockUnblock((objCategory)).then((response) => {
            if (response.code == "1") {
                isLoader(false)
                updateLearnerStatus(id, 0);
                TOAST_SUCCESS(response.message);
                GetTeacherData();
            } else {
                isLoader(false)
                TOAST_ERROR(response.message);
            }
        })
    };

    const updateLearnerStatus = (id, status) => {
        setData(prevData =>
            prevData.map(learner =>
                learner.id === id ? { ...learner, is_active: status } : learner
            )
        );
    };

    const subAdminPermission = () => {
        admin_permission_detail({ id: AdminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                    setPermission([])
                }
            })
            .catch((error) => {
                setPermission([])
            });
    }

    useEffect(() => {
        GetTeacherData();
        if (role !== "S") {
            subAdminPermission();
        }
    }, [search])

    const teacherPermission = permissions.find(permission => permission.menu_name === "Teacher");

    // Check if status,deleting is allowed for "Teachers"
    const isDeleteAllowed = teacherPermission && teacherPermission.delete === 1;
    const isStatusAllowed = teacherPermission && teacherPermission.status === 1;

    const getStatus = (step) => {
        switch (step) {
            case 0:
                return <button className="bg-warning border-0" style={{ width: "70px" }}>Pending</button>;
            case 1:
                return <button className="bg-warning border-0" style={{ width: "70px" }}>Pending</button>;
            case 2:
                return <button className="bg-success border-0 text-light" style={{ width: "70px" }}>Accepted</button>;
            case 3:
                return <button className="bg-danger text-light border-0" style={{ width: "70px" }}>Rejected</button>;
            default:
                return "";
        }
    };

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "60px"
        },
        {
            name: (
                <>
                    Registered<br />Date
                </>
            ),
            selector: row => {
                const date = new Date(row.created_at);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "110px"
        },
        {
            name: (
                <>
                    Id<br />Code
                </>
            ),
            selector: row => row.code_generate,
            sortable: true,
            width: "90px",
            cell: row => row.code_generate == null ? "0000" : row.code_generate
        },
        {
            name: 'Image',
            cell: (data) => (
                <img
                    src={data.profile_image}
                    style={{ width: '50px', height: '50px' }}
                />
            ),
            sortable: true,
            width: "80px"
        },
        {
            name: 'Full Name',
            selector: row => (
                <span onClick={() => handleView(row.id)} style={{ cursor: 'pointer' ,color :  "rgb(87, 8, 97)" }}>
                    {row.full_name}
                </span>
            ),
            sortable: true,
            width: "150px"
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "270px"
        },
        {
            name: 'Teach',
            cell: (row) => (
                <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }} onClick={() => handleView2(row.id)}>View Class</button>
            ),
            sortable: true,
            width: "100px"
        },
        {
            name: 'Application',
            cell: (row) => getStatus(row.step_1),
            sortable: true,
            width: "110px"
        },
        {
            name: (
                <>
                    Id<br />Verification
                </>
            ),
            cell: (row) => getStatus(row.step_2),
            sortable: true,
            width: "110px"
        },
        {
            name: (
                <>
                    Background<br />Check
                </>
            ),
            cell: (row) => getStatus(row.step_3),
            sortable: true,
            width: "110px"
        },
        {
            name: (
                <>
                    Teacher<br />Profile
                </>
            ),
            cell: (row) => getStatus(row.step_4),
            sortable: true,
            width: "100px"
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className="d-flex justify-content-center">

                    {(role === "S" || isDeleteAllowed) &&
                        <div className="col me-2">
                            <FontAwesome
                                name="delete"
                                className="fs-5 fa-solid fa-trash  mx-2 my-2 bg-light text-danger"
                                onClick={() => handleDelete(row.id)}
                            ></FontAwesome>
                        </div>
                    }

                    <div className="col me-2">
                        <FontAwesome
                            name="view"
                            className="fs-5 fa-solid fa-eye  mx-2 my-2 bg-light text-secondary"
                            onClick={() => handleView(row.id)}
                        ></FontAwesome>
                    </div>

                </div>
            ),
        },
        {
            name: role == "S" || isStatusAllowed ? 'Block/UnBlock' : '',
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    {(role === "S" || isStatusAllowed) && (
                        <BootstrapSwitchButton
                            checked={row.is_active === 1 ? true : false}
                            onlabel='Block'
                            offlabel='UnBlock'
                            width={90}
                            size="sm"
                            offstyle="light text-dark"
                            onChange={() => {
                                { clickActive(row.id, row.is_active, row.full_name, row.email) }
                            }}
                        />
                    )}
                </div>
            ),
        },
    ]

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">

                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <>
                    <div className="fs-3 fw-500 mt-4 ms-4 mb-2">
                        Teacher Data
                    </div>
                    <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                        <div className="row me-5">
                            <div className="col">
                                <CInputGroup>
                                    <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                        <FontAwesome name="search" className="fa-solid fa-search" />
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                    </div>

                    <div className="px-4 mt-1">
                        {data.length === 0 ? (
                            <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No Data Found</p>
                        ) : (
                            data && data.length > 0 && (
                                <DataTable
                                    persistTableHead
                                    columns={columns}
                                    data={data}
                                    pagination
                                    customStyles={customStyles}
                                    className="custom-data-table"
                                />
                            )
                        )}
                    </div>
                </>
            </div>
        </main>
    </>
}

